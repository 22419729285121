<template>
    <section class="section playlist" v-if="data && showPlaylist" :class="data.playlistType">
        <div class="container">
            <slot name="header">
                <h2 class="section-title">{{ data.displayTitle }}</h2>
            </slot>
            <div class="column-wrapper" v-if="playlistItems && playlistItems.length > 0">
                <VideoSliderBar :data="playlistItems" v-if="data.playlistType && data.playlistType == 'slider'" />

                <VideoGridCollection :data="playlistItems" :overviewItemType="overviewItemType"
                    v-if="data.playlistType && data.playlistType == 'overview'" />

                <!-- Show if is mobile overview -->
                <!-- <VideoSliderBar
					:data="playlistItems"
					v-if="data.playlistType && data.playlistType == 'overview'"
				/> -->
            </div>
            <slot name="footer">
                <div class="column-wrapper" v-if="data.buttonUrl">
                    <div class="column btn">
                        <a :href="data.buttonUrl">{{ data.buttonLabel }}</a>
                    </div>
                </div>
            </slot>
        </div>
    </section>
</template>

<script>
import {
    fetchPlaylistById,
    fetchMultipleVideos,
} from "@streampac.io/chef_sp_1";

// @import component
import VideoSliderBar from "@/components/VideoSliderBar";
import VideoGridCollection from "@/components/VideoGridCollection";

export default {
    name: "PlayList",
    components: {
        VideoSliderBar,
        VideoGridCollection,
    },
    inject: ["isAuthenticated"],
    data() {
        return {
            data: null,
            playlistItems: [],
            relatedContent: null,
            showPlaylist: false,
            fetchDataParams: {
                // paging: {
                // 	pageNumber: 1,
                // 	pageSize: 1,
                // },
                flattenFields: true,
            },
        };
    },
    props: ["playlistId", "overviewItemType", 'relatedContents', 'playlistType'],
    created() {
        //setTimeout(() => {
        //this.init();
        //}, 600);

        if (this.relatedContents && this.relatedContents.length > 0) {
            this.constructPlaylist();
        } else {
            this.init();
        }
    },
    mounted() { },
    methods: {
        init() {
            fetchPlaylistById(this.isAuthenticated, this.playlistId).then(
                (response) => {
                    this.data = response;

                    const pluck = (array, key) => {
                        return array.map((o) => o[key]);
                    };

                    if (
                        response.relatedContents &&
                        response.relatedContents.length > 0
                    ) {
                        let videoContentIds = [];

                        response.relatedContents.forEach((item) => {
                            if (item.entityType == "VideoContent") {
                                videoContentIds.push(item.contentId);
                            }
                        });

                        // response.relatedContents.forEach((item) => {
                        // 	if (item.entityType == "VideoContent") {
                        // 		this.playlistItems.push(item.videoContent);
                        // 		//videoContentIds.push(item.contentId);
                        // 	}
                        // });

                        // this.showPlaylist = true;

                        if (videoContentIds && videoContentIds.length > 0) {
                            fetchMultipleVideos(
                                this.isAuthenticated,
                                "?flattenFields=true",
                                videoContentIds
                            ).then((response) => {
                                let result = [];

                                videoContentIds.forEach(function (key) {
                                    var found = false;
                                    if (response && response.length > 0) {
                                        response.filter(function (item) {
                                            if (!found && item.id == key) {
                                                result.push(item);
                                                found = true;
                                                return false;
                                            } else return true;
                                        });
                                    }
                                });

                                this.playlistItems = result;
                                this.showPlaylist = true;
                            });
                        }
                    } else if (
                        response.playlistItems &&
                        response.playlistItems.length > 0
                    ) {
                        const videoContentIds = pluck(
                            response.playlistItems,
                            "videoContent"
                        );

                        fetchMultipleVideos(
                            this.isAuthenticated,
                            "?flattenFields=true",
                            videoContentIds
                        ).then((response) => {
                            this.playlistItems = response;
                            this.showPlaylist = true;
                        });
                    }
                }
            );
        },
        constructPlaylist() {
            this.data = {
                playlistType: this.playlistType
            };

            if (this.relatedContents && this.relatedContents.length > 0) {

                let videoContentIds = [];

                this.relatedContents.forEach((item) => {
                    if (item.entityType == "VideoContent") {
                        videoContentIds.push(item.contentId);
                    }
                });

                if (videoContentIds && videoContentIds.length > 0) {
                    fetchMultipleVideos(
                        this.isAuthenticated,
                        "?flattenFields=true",
                        videoContentIds
                    ).then((response) => {
                        let result = [];

                        videoContentIds.forEach(function (key) {
                            var found = false;
                            if (response && response.length > 0) {
                                response.filter(function (item) {
                                    if (!found && item.id == key) {
                                        result.push(item);
                                        found = true;
                                        return false;
                                    } else return true;
                                });
                            }
                        });

                        this.playlistItems = result;
                        this.showPlaylist = true;
                    });
                }
            }
        }
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>

<template>
    <!-- <section class="video-grid-collection" v-if="data && data.length > 0">
		<span class="anchor" id="video-grid-collection-anchor"></span>
		<div class="container">
			<div class="column-wrapper"> -->
    <div class="column grid-collection" v-if="data && data.length > 0">
        <span class="anchor" id="video-grid-collection-anchor"></span>
        <transition name="slow-fade">
            <div class="items-wrapper" v-if="!overviewItemType ||
                (overviewItemType && overviewItemType !== 'classes')
                ">
                <VideoOverviewItem v-for="item in data" :key="item.mediaId" :data="item" />
            </div>
        </transition>
        <transition name="slow-fade">
            <div class="items-wrapper" v-if="overviewItemType && overviewItemType == 'classes'">
                <LessonOverviewItem v-for="(item, index) in data" :key="item.id" :data="item" :itemIndex="index + 1" />
            </div>
        </transition>
    </div>
    <!-- </div>
		</div>
	</section> -->
</template>

<script>
// @import component
import VideoOverviewItem from "@/components/VideoOverviewItem";
import LessonOverviewItem from "@/components/LessonOverviewItem";

export default {
    name: "VideoGridCollection",
    components: {
        VideoOverviewItem,
        LessonOverviewItem,
    },
    inject: ["isAuthenticated"],
    data() {
        return {};
    },
    props: ["data", "overviewItemType"],
    created() {
        //console.log(this.data);
        //console.log(this.overviewItemType);
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>

<template>
    <div class="lesson-part part" v-if="data && data !== ''">
        <span class="part-index" v-if="itemIndex">Lesson {{ itemIndex }}:</span>
        <div class="global-item-wrapper">
            <div class="thumb-side">
                <img v-if="data.overviewImageUrl" :src="data.overviewImageUrl" />
                <img v-else-if="!data.overviewImageUrl && mediaDetailsObj" :src="getVixyThumbnailUrl()" />

                <button type="button" class="play-btn" @click="playPopupVideo"><img
                        src="@/assets/svgs/play-icon-white.svg" class="logo" /></button>
            </div>
            <div class="content-side" :class="{ 'partial-visible': textFullyVisible }">

                <h3 class="part-title">{{ data.title }}</h3>

                <ul class="labels" v-if="data.fields">
                    <li v-if="data.fields.skills &&
        data.fields.skills.length > 0
        ">
                        <div class="item-wrapper" v-for="(item) in data.fields.skills" :key="item.value">
                            <CatAsIcon :catValue="getEnumVal(item.value)" type="skill" />

                            <span class="label-wrapper">
                                {{ getEnumVal(item.value) }}
                            </span>
                        </div>
                    </li>

                    <li class="duration"
                        v-if="mediaDetailsObj && mediaDetailsObj.msDuration && mediaDetailsObj.msDuration !== ''">
                        <div class="item-wrapper">
                            <div class="icon-wrapper">
                                <img src="@/assets/svgs/duration-blue.svg" class="duration-icon" />
                            </div>
                            <span class="label-wrapper">{{ formatToClockDuration(mediaDetailsObj.msDuration)
                                }}</span>
                        </div>
                    </li>

                    <li v-if="data.fields.level &&
        data.fields.level.length > 0
        ">
                        <div class="item-wrapper" v-for="(item) in data.fields.level" :key="item.value">
                            <CatAsIcon :catValue="getEnumVal(item.value)" type="level" />

                            <span class="label-wrapper">
                                {{ getEnumVal(item.value) }}
                            </span>

                        </div>
                    </li>

                    <li class="trainer-wrapper" v-if="data.fields && data.fields.coachestrainers &&
        data.fields.coachestrainers.length > 0
        ">
                        <div class="item-wrapper" v-for="(item) in data.fields.coachestrainers" :key="item.value">
                            <div class="icon-wrapper">
                                <img src="@/assets/svgs/trainer.svg" class="duration-icon" />
                            </div>
                            <span class="label-wrapper">
                                {{ getEnumVal(item.value) }}
                            </span>

                        </div>
                    </li>
                </ul>

                <div class="textual-wrapper" v-if="data.content" v-html="data.content"></div>

                <div class="button-wrapper">
                    <button class="lesson-readmore-btn" type="button" v-if="!textFullyVisible"
                        @click="toggleTxtVisibility()">Show more</button>
                    <button class="lesson-readmore-btn" type="button" v-if="textFullyVisible"
                        @click="toggleTxtVisibility()">Show less</button>
                </div>
            </div>
        </div>
    </div>
    <transition name="fade">
        <Popup :elemVisible="showVideoPopup" :elemClass="'mini-popup'" @hidePopupEl="hidePopups()">
            <div class="column video-column">
                <div class="big-wrapper">
                    <button class="close-btn alt" @click="hidePopups()"></button>
                    <div :id="'popup-lesson-video-' + data.mediaId"
                        style="position: absolute; width: 100%; height: 100%">
                    </div>
                </div>
            </div>
        </Popup>
    </transition>
</template>

<script>
import {
    fetchVideoAsset,
} from "@streampac.io/chef_sp_1";

import CatAsIcon from "@/components/CatAsIcon";

import Popup from "@/components/Popup";


export default {
    data() {
        return {
            mediaDetailsObj: null,
            textFullyVisible: false,
            showVideoPopup: false,
        };
    },
    props: ["data", "itemIndex"],
    created() { },
    inject: ["isAuthenticated"],
    components: {
        CatAsIcon,
        Popup
    },
    mounted() {
        this.fetchDetails();
    },
    methods: {
        getEnumVal(val) {
            const enums = this.$store.state.getEnumLookup;

            if (enums && enums.length > 0) {
                const enumFilter = enums.filter((item) => item.id === val);

                if (enumFilter && enumFilter.length > 0) {
                    return enumFilter[0].enumValue;
                }
            }

            return "";
        },
        fetchDetails() {

            //Fetch video asset data
            fetchVideoAsset(this.isAuthenticated, this.data.mediaId).then(
                (response) => {
                    this.mediaDetailsObj = response;

                    //some extra time to display item. This way it has all the content loaded before displaying.
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 200);
                }
            );
        },
        getVixyThumbnailUrl() {
            if (this.mediaDetailsObj && this.mediaDetailsObj !== "") {
                const originalStr = this.mediaDetailsObj.thumbnailUrl;

                //const newStr = originalStr.replace("/width/200", "/width/600");
                const newStr = originalStr.replace(
                    "/width/200",
                    "/width/800/height/600/type/4/"
                );

                return newStr;
            }
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, 0);
        },
        formatToClockDuration(milliseconds) {
            let seconds = Math.floor(milliseconds / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);

            seconds = seconds % 60;

            minutes = seconds >= 30 ? minutes + 1 : minutes;

            minutes = minutes % 60;

            hours = hours % 24;

            if (hours > 0) {
                return `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)}:${this.padTo2Digits(seconds)}`;
            } else {
                return `${this.padTo2Digits(minutes)}:${this.padTo2Digits(seconds)}`;
            }
        },
        toggleTxtVisibility() {
            this.textFullyVisible = !this.textFullyVisible;
        },
        playPopupVideo() {
            this.showVideoPopup = true;
            this.initPopupPlayer();
        },
        hidePopups() {
            this.showVideoPopup = false;
        },
        initPopupPlayer() {
            const { kWidget } = window;

            //console.log(this.data);

            if (this.data && this.data.allowedToPlay) {
                setTimeout(() => {
                    kWidget.embed({
                        targetId: "popup-lesson-video-" + this.data.mediaId,
                        wid: "_" + this.$store.state.getVixyParams.partner,
                        uiconf_id: this.$store.state.getVixyParams.uiconfid,
                        flashvars: {
                            ks: this.data.kalturaSession,
                            playlistAPI: {
                                autoPlay: true,
                            },
                        },
                        entry_id: this.data.mediaId,
                    });
                }, 200);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>

<template>
    <section class="hero page" v-if="data" :class="[{ 'no-bg': !headerImage }, 'type-' + heroType]"
        :data-hero-size="heroSize">
        <div class="top-meta-bar">
            <div class="container">
                <button class="back-button" @click="
                    hasHistory() ? $router.go(-1) : $router.push('/')
                    ">
                    Go back
                </button>
            </div>
        </div>
        <div class="bg-vid-wrapper">
            <div class="bg-vid-inner-container" v-if="shortAssetData">
                <div class="short-vid-wrapper">
                    <div :id="'hero-' + shortAssetData.mediaId" style="position: absolute; width: 100%; height: 100%"></div>
                </div>
            </div>
            <transition name="vid-fade">
                <div class="thumbnail" v-if="!videoStarted && headerImage">
                    <div class="bg-wrapper" v-if="headerImage.url" v-bind:style="{
                        'background-image': 'url(' + headerImage.url + ')',
                    }"></div>
                </div>
            </transition>
        </div>
        <div class="container">
            <div class="column-wrapper" v-if="heroType &&
                heroType !== 'blog' || !heroType">
                <div class="column content">

                    <h1 class="page-title"
                        v-if="data.templateContentFields.displayTitle && data.templateContentFields.displayTitle !== ''"
                        v-html="data.templateContentFields.displayTitle"></h1>
                    <!-- <span class="subtitle" v-if="data.templateContentFields.headerSubtitle"
                        v-html="data.templateContentFields.headerSubtitle"></span> -->
                    <div class="intro" v-if="data.templateContentFields.headerIntroText &&
                        data.templateContentFields.headerIntroText !== '<p><br></p>'
                        " v-html="data.templateContentFields.headerIntroText"></div>
                    <!-- <ul class="button-wrapper" v-if="buttonData && buttonData.length > 0">
                        <li v-for="buttonItem in buttonData" :key="buttonItem.slug">
                            <router-link class="button" :data-txt="buttonItem.label" :to="buttonItem.slug">{{
                                buttonItem.label }}</router-link>
                        </li>
                    </ul> -->
                </div>
            </div>
            <div class="column-wrapper" v-else>
                <div class="column content">
                    <ul class="labels">
                        <li v-if="data.fields.blogitems &&
                            data.fields.blogitems.length > 0
                            " class="fullwidth">
                            <span class="item-wrapper" v-for="(item) in data.fields.blogitems" :key="item.value">
                                {{ getEnumVal(item.value) }}
                            </span>
                        </li>
                        <li>{{ getFormatDate(data.publicationDate) }}</li>
                    </ul>

                    <h1 class="page-title" v-html="data.templateContentFields.displayTitle"></h1>
                    <div v-html="data.templateContentFields.headerIntroText"></div>

                    <span class="author" v-if="data.fields.blogauthor &&
                        data.fields.blogauthor.length > 0
                        ">
                        By
                        <span class="item-wrapper" v-for="(item) in data.fields.blogauthor" :key="item.value">
                            {{ getEnumVal(item.value) }}
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {
    fetchCmsMedia,
    fetchVideoContentDetails,
} from "@streampac.io/chef_sp_1";

export default {
    data() {
        return {
            shortAssetData: null,
            headerImage: null,
            videoStarted: false,
            buttonData: [],
            videoShortUrl: null,
        };
    },
    inject: ["isAuthenticated"],
    props: ["data", "heroType", "heroSize", "vixyFlavorId"],
    components: {},
    created() {
        if (this.data) {
            if (this.data && this.data !== '' && this.data.templateContentMedia && this.data.templateContentMedia.headerImage !== "") {
                this.headerImage = this.data.templateContentMedia.headerImage;
            }

            if (
                this.data.templateContentFields.headerHighlightVideo &&
                this.data.templateContentFields.headerHighlightVideo !== "" &&
                this.data.templateContentFields.headerHighlightVideo !== undefined
            ) {
                fetchVideoContentDetails(
                    this.isAuthenticated,
                    this.data.templateContentFields.headerHighlightVideo,
                    "?flattenFields=true"
                ).then((response) => {
                    this.shortAssetData = response;

                    setTimeout(() => {
                        this.initPlayer();
                    }, 300);
                });
            }

            let buttonArray = [];

            if (this.data.headerButtonUrl && this.data.headerButtonUrl !== "") {
                let buttonLabel = "Read more";

                if (
                    this.data.headerButtonLabel &&
                    this.data.headerButtonLabel !== ""
                ) {
                    buttonLabel = this.data.headerButtonLabel;
                }

                const buttonItem = {
                    slug: "/" + this.data.headerButtonUrl,
                    label: buttonLabel,
                };

                buttonArray.push(buttonItem);
            }

            if (
                this.data.headerSecondButtonUrl &&
                this.data.headerSecondButtonUrl !== ""
            ) {
                let buttonLabel = "Read more";

                if (
                    this.data.headerSecondButtonLabel &&
                    this.data.headerSecondButtonLabel !== ""
                ) {
                    buttonLabel = this.data.headerSecondButtonLabel;
                }

                const buttonItem = {
                    slug: "/" + this.data.headerSecondButtonUrl,
                    label: buttonLabel,
                };

                buttonArray.push(buttonItem);
            }

            this.buttonData = buttonArray;
        }
    },
    methods: {
        resolveCmsMedia(val, name) {
            if (val !== "") {
                fetchCmsMedia(this.isAuthenticated, val).then((response) => {
                    this[name] = response;
                });
            }
        },
        stripHtml(val) {
            const strippedString = val.replace(/(<([^>]+)>)/gi, "");

            return strippedString;
        },
        initPlayer() {
            if (this.shortAssetData && this.shortAssetData.kalturaSession && this.shortAssetData.kalturaSession !== '') {

                const { kWidget } = window;

                kWidget.embed({
                    targetId: "hero-" + this.shortAssetData.mediaId,
                    wid: "_" + this.$store.state.getVixyParams.partner,
                    uiconf_id: this.$store.state.getVixyParams.uiconfid,
                    flashvars: {
                        ks: this.shortAssetData.kalturaSession,
                        loop: true,
                        playlistAPI: {
                            autoPlay: true,
                        },
                        //Remove controls
                        "controlBarContainer.plugin": false,
                        "largePlayBtn.plugin": false,
                        "loadingSpinner.plugin": false,
                    },
                    entry_id: this.shortAssetData.mediaId,
                    readyCallback: () => {
                        this.videoStarted = true;
                    },
                });
            }
        },
        formatDate() {
            let initialDate = this.data.publicationDate;

            const months = [
                "Jan.",
                "Feb.",
                "Mrt.",
                "Apr.",
                "Mei.",
                "Jun.",
                "Jul.",
                "Aug.",
                "Sept.",
                "Okt.",
                "Nov.",
                "Dec.",
            ];

            let d = new Date(initialDate);

            const monthIndex = d.getMonth();
            const monthName = months[monthIndex];
            const year = d.getFullYear();
            const day = d.getDate();

            return day + " " + monthName + " " + year;
        },
        getEnumVal(val) {
            const enums = this.$store.state.getEnumLookup;

            if (enums && enums.length > 0) {
                const enumFilter = enums.filter((item) => item.id === val);

                if (enumFilter && enumFilter.length > 0) {
                    if (enumFilter && enumFilter.length > 0) {
                        return enumFilter[0].enumValue;
                    }
                } else {
                    return "";
                }
            }

            return "";
        },
        getFormatDate(val) {
            let d = new Date(val);

            const monthIndex = d.getMonth() + 1;
            const year = d.getFullYear();
            const day = d.getDate();

            return day + "-" + monthIndex + "-" + year;
        },
        hasHistory() {
            return window.history.length > 2;
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>

<style lang="scss"></style>
